import { Book } from './book'

export enum AccountStatus {
  CONFIRMED = 0,
  UNCONFIRMED = 1,
  DISABLED = 2
}

export type User = {
  first_kana: string
  sex: string
  current_status: string
  id: number
  home_location_lat: number
  phone_number: string
  phone_number1: string
  phone_number2: string
  phone_number_landline: string
  username: string
  first_name: string
  email: string
  usage: string
  memo: string
  message_template: string // 2023-05 伝言テンプレート対応
  status_disabled: string
  automatic_voice_call_notification: boolean
  bus_operation_status_push_notification: boolean
  status_confirmed: string
  status_unconfirmed: string
  allow_use_of_location_information: boolean
  id_number: number
  shared_taxi_approach_push_notification: boolean
  shared_taxi_approach_webpush_notification: boolean // WebPush
  last_kana: string
  home_location_lng: number
  home_location_name: string
  last_name: string
  country_name: string
  birthday: Date
  home_location: string
  address_attr: number
  address: string
  area_id: number
  area_name: string
  block: string
  homeLocation: {
    lat: number
    lng: number
  }
  lat: number
  lng: number
  agreed: boolean
  landmark_id: string
  landmark_address_attr: number
  landmark_address: string
  disabled: boolean
  accountStatus: AccountStatus
  histories: Array<Book>
  alert_memo: string // 2021-07-05 Alert Add
  coupon_user: boolean // 2022-06 クーポン対応
  reservation_remind: number
}

export const searchByPhone = ($axios: any, val: string): Promise<User[]> => {
  return $axios.$get(`/users/?phone=${val}`).then((res: User[]) => {
    return res
  })
}
export const searchByName = ($axios: any, val: string): Promise<User[]> => {
  return $axios.$get(`/users/?name=${val}`).then((res: User[]) => {
    return res
  })
}

export const getById = ($axios: any, id: number): Promise<User> => {
  return $axios.$get(`/users/${id}`).then((res: User) => {
    return res
  })
}

export const list = (
  $axios: any,
  limit: number = 15,
  offset: number = 0,
  name: string = '',
  phone: string = '',
  accountStatus: string = '',
  sort: string[] = []
): Promise<[number, User[]]> => {
  const filters = []
  if (accountStatus === 'CONFIRMED') {
    filters.push(`status eq 2048`)
  } else if (accountStatus === 'UNCONFIRMED') {
    filters.push(`status eq 512`)
  }else if (accountStatus === 'DISABLED') {
    filters.push(`status eq 256`)
  }
  const filter = filters.length > 0 ? `&filter=${filters.join(' and ')}` : ''
  const orderBy = sort.length > 0 ? `&orderby=${sort.join(',')}` : ''
  return $axios
    .post(
      `/users/getUserInfo?limit=${limit}&offset=${offset}${filter}${orderBy}`,
      {
        // eslint-disable-next-line object-shorthand
        name: name,
        // eslint-disable-next-line object-shorthand
        phone: phone
      }
    )
    .then((res: any) => {
      const total = res.headers['x-total-count']
        ? Number(res.headers['x-total-count'])
        : 0
      return [total, res.data]
    })
    .catch((err: any) => {
      console.error('POST request failed:', err)
    })
}

// 2021-05-27 自宅乗降場対応 - start
export const landmarkUser = (
  $axios: any,
  landmark: string = ''
): Promise<[number, User[]]> => {
  return $axios
    .get(`/users/?filter=landmark_id eq ${landmark}`)
    .then((res: any) => {
      const total = res.headers['x-total-count']
        ? Number(res.headers['x-total-count'])
        : 0
      return [total, res.data]
    })
}
// 2021-05-27 自宅乗降場対応 - end

export const useCoupon = ($axios: any) => {
  return $axios.$get(`/users/coupon`).then((use: boolean) => {
    return use
  })
}

export const searchUsersByPhone = ($axios: any, val: string) => {
  const urlParams = new URLSearchParams({
    phone_number: String(val)
  })
  return $axios.$post(`/users/usersearchByPhone`, urlParams)
}
